@import '../scss/buttons';

.hidden {
    display: none;
}

.popout-panel {
    color: $white;
    position: relative;
    bottom: 0px;
    height: 48px;
    width: 48px;
    margin-left: auto;
    svg {
        fill: currentColor;
    }
}

.popout-panel_panel-opener__button {
    width: $touchbutton-width;
    height: $touchbutton-height;
    margin-left: auto;
    position: relative;
    right: 28px;
    top: 10px;
}

.popout-panel__panel-closer__button {
    width: $touchbutton-width;
    height: $touchbutton-height;
    text-align: center;
    padding: 0;
    margin: 0;
    background: transparent;
    color: $white;
    cursor: pointer;
    transition: .18s ease-out;
    &:hover {
        background: $white;
        color: $black;
    }
}

.popout-panel__app-info-message {

}

clipboard-copy {
    position: relative;
    top: 0.05em;
    margin-left: .5em;
}