button {
  background: rgb(28, 184, 65);
  border-radius: 4px;
  border: 1px solid #999;
  border: transparent;
  color: rgba(0, 0, 0, .8);
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 100%;
  padding: .5em 1em;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  vertical-align: middle;
  white-space: nowrap;
  zoom: 1;
}

$touchbutton-width: 28px;
$touchbutton-height: 28px;