/* login.scss */
@import 'scss/variables';
@import 'scss/mixins';
@import 'partials/app-info';

html,
body {
  box-sizing: border-box;
  height: 100%;
}

html {
  overflow: hidden;
}

body {
  font-family: "Helvetica Neue", HelveticaNeue, "TeX Gyre Heros", TeXGyreHeros, FreeSans, "Nimbus Sans L",
    "Liberation Sans", "Roboto", Helvetica, Arial, sans-serif;
  text-rendering: optimizeLegibility;
  margin: 0;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 152px 1fr 48px;
  grid-template-rows: 152px 1fr 48px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    "header header header"
    "main main main"
    "footer footer footer";
  grid-gap: 0em;

  background-image: url("./images/login_bgrd_tile.png");
  background-repeat: repeat;

  header {
    grid-row: 1;
    grid-area: header;
    -ms-grid-column-span: 3;

    .logo-container__header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      background-color: $white;
      box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.35);
      padding-top: 25px;
      padding-bottom: 25px;
      img.edmentum-logo {
        height: auto;
      }
    }
  }

  main {
    grid-row: 2;
    -ms-grid-column-span: 3;
    grid-area: main;
    margin: 0 auto;
    z-index: 10;

    // display: -ms-grid;
    // display: grid;
    // grid-gap: 10px 2em;
    // -ms-grid-columns: 1fr;
    // grid-template-areas:
    //   "top"
    //   "middle"
    //   "bottom";

    section.login-panel {
      background-color: $white;
      box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.35);
      border-radius: 6px;
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      margin-bottom: 40px;
      width: 400px;
      //height: 420px;
      justify-self: center;
      overflow: hidden;

      .row.ext-login {
        margin-top: 10px;
        margin-bottom: 10px;

        .col-xs-12 {
          width: 100%;
        }

        .ext-login-statement {
          text-align: center;
          font-weight: bold;
          margin-bottom: 8px;
        }

        .external-providers-container {
          display: flex;
          justify-content: center;
          flex-direction: row;
          .link-container {
            display: flex;
            justify-content: center;
            padding: 4px;
          }
        }
        .external-providers-container > a {
          min-width: 130px;
          max-width: 130px;
          margin-top: 5px;
          display: flex;
          justify-content: flex-start;
          text-align: center;
        }

        .text-muted {
          color: #777;
          font-family: "Trebuchet MS", Helvetica, sans-serif;
          font-size: 12px;
        }

        .btn {
          display: inline-block;
          padding: 6px 12px;
          margin-bottom: 0;
          font-size: 14px;
          font-weight: normal;
          line-height: 1.42857143;
          text-align: center;
          white-space: nowrap;
          vertical-align: middle;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          background-image: none;
          border: 1px solid transparent;
          border-radius: 4px;
        }

        .btn:hover {
          color: #333;
          background-color: #e6e6e6;
          border-color: #398439;
          text-decoration: none;
        }

        a {
          color: #428bca;
          text-decoration: none;
        }

        .btn-sso {
          padding: 0px;
          margin: 0px;
          text-align: left;
          height: 33px;
        }

        .btn-ClassLink,
        .btn-Clever,
        .btn-Google,
        .btn-Microsoft {
          background-color: #fff;
          border: solid 1.5px #4274f6;
          color: black;
          line-height: 2em;
          width: 130px;
          .icon-container {
            background-image: url('./images/clever.png');
            background-size: cover;
            background-repeat: no-repeat;
            width: 30px;
            height: 30px;
            position: relative;
            left: 2px;
            top: 1px;
            display: inline-block;
          }

          .text-container {
            display: inline-block;
            vertical-align: top;
            text-align: center;
            line-height: 30px;
            padding-right: 10px;
            width: 70%;
          }
        }

        .btn-ClassLink {
          border-color: #35abcd;
          .icon-container {
            background-image: url('./images/classlink-sso.png');
            background-size: cover;
            background-repeat: no-repeat;
            width: 30px;
            height: 30px;
            display: inline-block;
          }
        }

        .btn-Google {
          border-color: #F14436;
          .icon-container {
            background-image: url('./images/google-sso.png');
            background-size: cover;
            background-repeat: no-repeat;
            width: 30px;
            height: 30px;
            display: inline-block;
          }
        }

        .btn-Microsoft {
          border-color: #00B4F1;
          .icon-container {
            background-image: url('./images/microsoft-sso.png');
            background-size: cover;
            background-repeat: no-repeat;
            width: 30px;
            height: 30px;
            display: inline-block;
          }
        }
      }

      .calvert-logo-container {
        text-align: center;
        img.calvert-logo {
          //
        }
      }
    }

    section#notification.default {
      display: none;
    }

    section#notification {
      background-color: $white;
      box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.35);
      border-radius: 6px;
      box-sizing: border-box;
      padding-left: 32px;
      padding-right: 32px;
      padding-bottom: 16px;
      margin: 0;
      margin-bottom: 24px;
      width: 400px;
      justify-self: center;
      overflow: hidden;
      animation: 300ms ease-out 0s 1 fadein;
      will-change: transform;
      z-index: 0;

      header > h1 {
        font-size: 16px;
        font-weight: 500;
        color: $gray-800;
        padding: 0;
        margin-bottom: 0;
      }

      section > h2 {
        font-size: 0.85em;
        font-weight: 400;
        color: $gray-600;
        padding: 0;
        margin-top: 0;
      }

      section > hr {
        border: 0;
        height: 1px;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.05));
        //background-image: linear-gradient(to right, rgba(8, 90, 166, 0.05), rgba(8, 90, 166, 0.75), rgba(8, 90, 166, 0.05));
      }

      section > p {
        font-size: 0.75em;
        font-weight: 400;
        color: $gray-600;
        margin-bottom: 0;
      }

      section > strong {
        font-weight: 700;
      }
    }

    section#support {
      background-color: $white;
      box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.35);
      border-radius: 6px;
      box-sizing: border-box;
      padding-left: 32px;
      padding-right: 32px;
      padding-bottom: 16px;
      margin: 0;
      width: 400px;
      justify-self: center;
      overflow: hidden;
      font-size: 16px;

      > p {
        font-size: 0.75em;
        font-weight: 400;
        color: $gray-600;
        margin-bottom: 0;
      }

      strong {
        font-weight: 700;
        color: $gray-800;
      }
      a {
        text-decoration: none;
        color: $gray-800;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  footer {
    grid-row: 3;
    -ms-grid-column-span: 3;
    grid-area: footer;
    background-color: white;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 324px 1fr 324px;
    grid-template-columns: 324px 1fr 324px;
    grid-template-areas: "left-corner center right-corner";
    height: 48px;
    font-size: 12pt;
    color: white;

    .footer__left-corner {
      grid-column: 1;
      grid-area: left-corner;
      display: flex;
      align-items: center;
      //justify-content: center;
      height: 48px;

      .logo-container__footer {
        display: inline;
        padding-left: 12px;
        img.edmentum-logo {
          width: 128px;
          height: auto;
        }
      }
    }

    .footer__center {
      grid-column: 2;
      grid-area: center;
      height: 48px;
      line-height: 48px;
      white-space: nowrap;
      text-align: center;
      .gray-text__tiny {
        color: $gray-600;
        font-size: small;
      }
    }

    .footer__right-corner {
      grid-column: 3;
      grid-area: right-corner;
      width: 100%;
      height: 48px;
      font-size: small;
      text-align: left;
      line-height: 48px;
      font-weight: 300;
      color: $gray-600;
      a {
        font-weight: bold;
        color: #428bca;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.edmentum-color-bar {
  display: flex;
  flex-direction: row;
  // width: 100%;
  // height: 9px;
  // padding: 0;
  // margin: 0;

  .stripes {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: left;
    // margin: 0 auto;
    // width: 1024px;
    // background-color: #3299d8;
    // overflow: hidden;

    div {
      height: 9px;
      float: left;
    }

    .first-stripe {
      flex-grow: 4;
      background-color: #b064a7;
    }

    .second-stripe {
      width: 90px;
      background-color: #bad737;
    }

    .third-stripe {
      width: 90px;
      background-color: #60ba61;
    }

    .fourth-stripe {
      width: 90px;
      background-color: #d9df5e;
    }

    .fifth-stripe {
      width: 55px;
      background-color: #fadf23;
    }

    .sixth-stripe {
      width: 135px;
      background-color: #ea6439;
    }

    .seventh-stripe {
      width: 62px;
      background-color: #f2a42c;
    }

    .eighth-stripe {
      width: 82px;
      background-color: #e5006e;
    }

    .ninth-stripe {
      flex-grow: 4;
      background-color: #3299d8;
    }
  }
}

form.login {
  padding: 8px;
}

.input-field-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  .form-group {
    margin-left: 14px;
    .input-field-container {
      width: 340px;
    }
  }
}

@keyframes slidein {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadein {
  0% {
    //transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    //transform: translateY(0%);
    opacity: 1;
  }
}

.input-field-container.identifierPassword {
  //animation: 300ms ease-out 0s 1 slidein;
  //will-change: transform;
}

.input-field-leading-icon {
  height: 28px;
  width: 28px;
  padding: 10px;
  background-color: #dddddd;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border: 1px solid #f8f9fa;
  border-right: none;
  color: #a5a5a5;
}

.input-field-leading-icon.has-input {
  color: green;
}

.input-field-leading-icon>svg {
  fill: currentColor;
}

input::-webkit-input-placeholder {
  color: #ced4da;
}

input:-ms-input-placeholder {
  color: #ced4da;
}

input::-ms-input-placeholder {
  color: #ced4da;
}

input::placeholder {
  color: #ced4da;
}

input {
  position: relative;
  left: 0px;
  background: #fff;
  color: #212529;
  border-radius: 6px;
  // border-top-left-radius: 0px;
  // border-bottom-left-radius: 0px;
  border: 1px solid #f8f9fa;
  border-left: none;
  box-shadow: inset 1px 2px 8px rgba(0, 0, 0, 0.15);
  font-family: inherit;
  font-size: 18pt;
  line-height: 1.45em;
  outline: none;
  padding-left: 0.2em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  box-sizing: border-box;
  transition: .18s ease-out;
  autocomplete: "off";
  autocorrect: "off";
  spellcheck: "false";
  height: 50px;
  width: 340px;
  //width: 80%;
  max-width: 400px;
}

input:hover {
  box-shadow: inset 1px 2px 8px rgba(0, 0, 0, 0.02);
}

input:focus {
  color: #4b515d;
  border: 1px solid #B8B6B6;
  border-left: none;
  box-shadow: inset 2px 2px 8px rgba(0, 0, 0, 0.25);
}

.login-actions-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.login-actions {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: space-between;
  max-width: 450px;
  width: 85%;
}

.forgot-message {
  padding-top: 4px;
  text-align: right;
}

.forgot-message a {
  color: #00659c;
  text-decoration: none;
  padding: 4px;
  transition: .18s ease-out;
  border-radius: 3px;
  //margin-right: 20px;
}

.forgot-message a:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.sign-in-button {
  color: white;
  background-color: #2eaa33;
  width: 100%;
  height: 48px;
  line-height: 48px;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  text-align: center;
}

.sign-in-button.disabled {
  cursor: default;
  //visibility: hidden;
}

.login-error {
  color: red;
  margin-left: 30px;
  margin-right: 30px;
  padding: 4px;
}

.visible {
  @include invisible(visible);
}

.invisible {
  @include invisible(hidden);
}

@media only screen and (min-width: 0px) and (max-width: 960px) {
  body {
    footer {
      -ms-grid-columns: 100px 1fr 324px;
      grid-template-columns: 100px 1fr 324px;
    }
  }

  .footer__left-corner {
    display: none;
  }
  .footer__center {
    display: none;
  }
}
